import Header from "../../components/Header";
import '../How-to-play/index.css'

const Index = () => {
    return (
      <div className="how">
        <Header />

        <div className="py-8 items-center content-center how-body">
          <div className="hp-header">To become a supervisor</div>
          <p className="hp-p1">NOTE: You must be 18 years and above </p>
          <p className="hp-p">
            Click on any of the description below to watch a video on how{" "}
          </p>

          <div className="hp-p pr-6 w-full md:w-2/3 mb-5">
            <a
              href="https://drive.google.com/file/d/1HOnY6bOjwLARirVhAuyITPkBdJ6yEJs1/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to set target</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1M_LnG_lJYRN8S__CFvf5_5nlNPFjz_Dm/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to view target</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1T_-SD_9F67VZrCq_fw9hlNTULAPDs6x0/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">Eliest USSD registration</div>
            </a>
          </div>

          {/* <div className="hp-p pr-6 w-full md:w-2/3">
            <div className="text">Register on www.eliestlotto.biz </div>

            <div className="text">
              By default upon registration, you are a recruiting supervisor.
            </div>

            <div className="text">
              All paying supervisors would be assigned agents, while recruiting
              supervisors will need to meet the requirements.
            </div>

            <div className="text">
              Activate your account with the required sum
            </div>

            <div className="text">Monitor your agents</div>

            <div className="text">Check your profile for referral code </div>

            <div className="text">You can add agents too</div>
          </div> */}
        </div>
      </div>
    );
}

export default Index;