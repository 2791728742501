import axios from 'axios'

const AGENT_ENDPOINT = process.env.REACT_APP_AGENTS_ENDPOINT;

console.log(AGENT_ENDPOINT)

export const getTopAgents = async () => {
    try {
        const res = await axios.get(`${AGENT_ENDPOINT}top-agent`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(res);
        return res;
    } catch (error) {
        console.log(error);
       return error;
    }
};