import Header from "../../components/Header";
import '../How-to-play/index.css'

const Index = () => {
    return (
      <div className="how">
        <Header />

        <div className="py-8 items-center content-center how-body">
          <div className="hp-header">To become An Agent you need:</div>
          <p className="hp-p1">NOTE: You must be 18 years and above </p>
          <p className="hp-p">
            Click on any of the description below to watch a video on how{" "}
          </p>

          <div className="hp-p pr-6 w-full md:w-2/3">
            <a
              href="https://drive.google.com/file/d/1rVVRv24cKv9O143ufFC7_Jd_TSuMb1Rx/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to become an agent</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1_bV2QaoO5BF4dg-oRmwgZdJMtIzWkdv5/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to sign in to your agent account</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1Db9tU05qAPnuRjEcFVq88yvi5ZPVVjES/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to transfer winnings to agent</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1b_K4avh318Bofgrz8Z6bWxwXM8-909mC/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">
                How to transfer winning to bank account
              </div>
            </a>

            <a
              href="https://drive.google.com/file/d/1TrG-VA65umjVNzCOKuvs16h_uAwe1nTh/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to view agent profile</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1HOnY6bOjwLARirVhAuyITPkBdJ6yEJs1/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to set target</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1M_LnG_lJYRN8S__CFvf5_5nlNPFjz_Dm/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to view target</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1kGV9vwaQFO4VbOzM48iLYdv7-XxRUsJ0/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to recharge agents</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1bI_OyzaPvvTQl9S5YPxPPJ_Y7yoVNguS/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to check wallet balance on USSD</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1QaO8IXGhopoNMR96lqM0EhDG0cG6DE1q/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to activate agent account</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1QaO8IXGhopoNMR96lqM0EhDG0cG6DE1q/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to check transaction</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1K_1uMSV7KFgpl30mgvHw8MX_00BUxcBM/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to print voucher</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1hVgRw-OMrW5HpieWY-b1we6lybA0HvlX/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to play scratch & win</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1KEIiwxAgh28ZxpfXKdX_kOQtLUUbRO88/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to generate new voucher</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1yh0ZPhvT1r7ssxxUUfYiKFsRSrv861cm/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to fund bank account modified</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1qu31nxjjwH-_vg9wSTVuqxAuJABKcqee/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to fund agent wallet</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1tE8fUHAD1mRtQmsXROArUh1yt7bhz5jF/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to fund through voucher</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1NzhppLyPXWTsg7EDRqCmeVff1sx0F9AX/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to play 2 of 90 part 1</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1NyqmfztGpglz69XuzfVCJIKbhMHi1VsG/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to play 2 of 90 part 2 </div>
            </a>

            <a
              href="https://drive.google.com/file/d/1OWRc1iv35d9KUpGoc688Zzj9mX0WspUq/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">How to check for downlines</div>
            </a>

            <a
              href="https://drive.google.com/file/d/1T_-SD_9F67VZrCq_fw9hlNTULAPDs6x0/view?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              <div className="text">Eliest ussd registration</div>
            </a>

            {/* <div className="text">
              An internet connected device as you can only be registered as an
              agent online.
            </div>

            <div className="text">Bank account</div>

            <div className="text">
              Register on our website www.eliestlotto.biz
            </div>

            <div className="text">
              Fund your wallet with #1,000 ( #500 would be used to activate your
              agent account.).
            </div>

            <div className="text">
              Use your referral code to refer agents and enjoy our multi ways of
              earning on our agents platform. The following are found on agent
              page 
            </div>

            <div className="text">Play: Agent can play for both player </div>

            <div className="text">
              Wallet: It shows both fund and wining wallet balance
            </div>

            <div className="text">
              Recharge a player by inputting his phone number and amount
            </div>

            <div className="text">
              Transfer to another agent by inputting his phone number and amount
            </div>

            <div className="text">Target: set your monthly target here</div>

            <div className="text">
              New Voucher:  Generate vouchers to sell to players
            </div>

            <div className="text">
              Voucher Batches: All generated vouchers are stored here. 
            </div>

            <div className="text">
              Downlines: All referred agents are stored here. 
            </div>

            <div className="text">
              Profile : Contains all relevant information and your referral
              code.
            </div>

            <div className="text">
              Target: Set target progress can be monitored here. 
            </div> */}
          </div>
        </div>
      </div>
    );
}

export default Index;